/* CarForm.css */

.form-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 40px;
    background-color: #f7f9fc;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    color: #333;
    margin-bottom: 20px;
  }
  
  .car-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .form-select,
  .form-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
  }
  
  .form-select:focus,
  .form-input:focus {
    border-color: #61dafb;
  }
  
  .form-button {
    padding: 12px;
    font-size: 16px;
    color: white;
    background-color: #61dafb;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .form-button:hover {
    background-color: #21a1f1;
  }
  
  .result {
    font-size: 18px;
    color: #333;
    margin-top: 20px;
    font-weight: bold;
  }
  